import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Img from 'gatsby-image'

const PhotoGallerySection = styled.section``

const GridContainer = styled.div`
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  display: grid;
`

const ImageWrapper = styled.div`
  width: 100%;
  display: block;
  @media (max-width: 992px) {
    grid-column-start: 1;
    grid-column-end: 3;
  }
`
const PhotoGallery = () => (
  <StaticQuery
    query={graphql`
      query Photos {
        allContentfulPhotoGallery {
          edges {
            node {
              id
              title
              photos {
                id
                title
                fluid(maxWidth: 700) {
                  ...GatsbyContentfulFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      console.log(data.allContentfulPhotoGallery.edges[0].node.photos)
      return (
        <>
          <Layout>
            <SEO title="Photo Gallery" />
            <PhotoGallerySection className="container brand-padding-y">
              <GridContainer>
                {data.allContentfulPhotoGallery.edges[0].node.photos.map(
                  (item, i) => (
                    <ImageWrapper id={item.id}>
                      <Img fluid={item.fluid} />
                    </ImageWrapper>
                  )
                )}
              </GridContainer>
            </PhotoGallerySection>
          </Layout>
        </>
      )
    }}
  />
)

export default PhotoGallery
